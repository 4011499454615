/* About.css */

.about-container {
  margin-top: 70px;
}

/* Ensure images scale correctly */
.custom-image {
  width: 100%;
  height: 90vh;
  overflow: visible;
  /* margin-top: 7%; */
}




/* Text container */
.containertext {
  max-width:130%;
    margin: 0 auto;
    font-family: 'Jost',sans-serif;
}

  /* Ensure images scale correctly */
  
  /* Heading Styles */
  .h1text {
    font-size: 2.5rem;
    margin-top:-80px;
    text-align: center;
    text-decoration: underline;
    font-weight: bold;
    font-family: 'Jost',sans-serif;
  }
  .h2text{
    font-size: 2rem;
    background: linear-gradient(90deg, #0A466d, #f7941d); /* Define the gradient colors */
    background-clip: text; 
    -webkit-background-clip: text; /* Clip the background to the text */
    color: transparent;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: center;
    font-family: 'Jost',sans-serif;
  }
  /* Text container */
 
  
  /* About details text */
  
  
  /* Core Values Section */
  .values .vour {
    text-align: center;
    margin-top: 30px;
    color: #0a466d;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: -10px;
  }
  
  /* Wrapper for the entire image slider with horizontal scrolling */
.slider-wrapper {
  display: flex;
  justify-content: center;
  overflow-x: auto;  /* Enables horizontal scrolling */
  padding: 10px;
  border-radius: 10px;
  width: 85%;  /* Ensure the width is 100% to fill the container */
  scrollbar-width: thin; /* Firefox custom scrollbar */
  margin-left: 80px;
}

/* Image slider container */
.image-slider {
  display: flex;  /* Align images in a horizontal row */
  gap: 20px;  /* Add space between images */
  flex-wrap: nowrap;  /* Prevent images from wrapping to the next line */
}

/* Image style */
.slider-image {
  text-align: center; /* Center-align the content */
  margin-bottom: 20px;
}

/* Style for images inside the slider */
.slider-image img {
  width: 200px;  /* Set the width of each image */
  height: auto;  /* Maintain aspect ratio */
  display: block;
  border-radius: 8px;  /* Optional: Add rounded corners to images */
}

/* Title below the image */
.image-title {
  margin-top: 10px;  /* Add space between the image and the title */
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
  text-align: center;
}

/* Custom Scrollbar Styling */
.slider-wrapper::-webkit-scrollbar {
  height: 8px;  /* Height of the scrollbar */
}

.slider-wrapper::-webkit-scrollbar-thumb {
  background-color: #888;  /* Color of the scrollbar */
  border-radius: 10px;  /* Rounded corners */
}

.slider-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #555;  /* Darker color when hovered */
}

.slider-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1;  /* Track color */
}

  /* Vision and Mission Section */
  .vision-mission-wrapper {
    margin-top: 100px;
    padding: 30px;
  }
  
  .vision-container, .mission-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .vision-content, .mission-content {
    text-align: center;
    max-width: 700px;
    margin-top: 20px;
    
  }
  
  .vision-heading, .mission-heading {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
    color: #0a466d;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 2rem;
  }
  
  .vision-image, .mission-image {
    width: 100%;
    max-width: 200px;
    margin-top: 20px;
    object-fit: contain;
  }
  
  /* Responsive Design Adjustments */
  @media (max-width: 768px) {
    .h1text, .h2text {
      font-size: 2rem;
    }
  
    .about-details {
      font-size: 1rem;
    }
  
    .slider-image {
      flex: 1 1 100%;
    }
  
    .vision-image, .mission-image {
      width: 80%;
      max-width: 150px;
    }
  
    .vision-heading, .mission-heading {
      font-size: 1.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .h1text, .h2text {
      font-size: 1.5rem;
    }
  
    .about-details {
      font-size: 0.9rem;
    }
  
    .vision-image, .mission-image {
      width: 70%;
    }
  
    .vision-heading, .mission-heading {
      font-size: 1.5rem;
    }
  
    .containertext {
      padding: 10px;
    }
  }
  



.about-details {
  text-align: justify;
  padding: 0 20px;
  margin: 0 auto;
  width: 90%;
  font-size: 1.2rem;
  line-height: 1.5;
}
/* 
.values {
  margin-top: 30px;
} */
/* 
.vour {
  font-size: 3rem;
  font-weight: bold;
  margin-top: -10px;
} */



.overlap-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  z-index: 1;
}

.slider-wrapper {
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
  border-radius: 10px;
}

.image-slider {
  display: flex;
  overflow-x: auto;
  padding: 5px;
  scroll-snap-type: x mandatory;
}

.slider-image {
  flex: 0 0 auto;
  margin-right: 50px;
}

.image-slider img {
  width: 150px;
  height: auto;
  max-height: 150px;
  border-radius: 8px;
}

.image-slider::-webkit-scrollbar {
  height: 3px;
}

.image-slider::-webkit-scrollbar-thumb {
  background: #f7941d;
  border-radius: 10px;
}

.image-slider::-webkit-scrollbar-thumb:hover {
  background:  #f7941d;
}
/* Vision and Mission Wrapper */
.vision-mission-wrapper {
display: flex;
justify-content: space-between;
align-items: center;
max-width: 1140px; /* Max width same as Navbar container */
margin: 0 auto;
padding: 20px;
}

/* Vision and Mission Containers */
.vision-container,
.mission-container {
flex: 1;
padding: 20px;
border: 2px solid #ccc;
border-radius: 10px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
position: relative;
text-align: center;
box-sizing: border-box;
margin: 0 10px; /* Small gap between containers */
max-width: 100%;
}

/* Vision and Mission Headings */
.vision-heading,
.mission-heading {
text-align: center;
font-size: 1.8rem;
font-weight: bold;
margin-bottom: 10px;
}

/* Vision and Mission Content */
.vision-content,
.mission-content {
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 10px;
}

.vision-content p,
.mission-content p {
width: 70%; /* Reduced width for content */
font-size: 1.1rem;
text-align: justify;
}

.vision-image,
.mission-image {
width: 150px;
height: auto;
margin-left: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
.vision-mission-wrapper {
  flex-direction: column; /* Stack the containers vertically */
  max-width: 100%;
}

.vision-container,
.mission-container {
  width: 100%; /* Full width containers */
}

.vision-content,
.mission-content {
  flex-direction: row; /* Keep text and image side by side */
  align-items: center;
}

.vision-content p,
.mission-content p {
  width: 60%; /* Reduce the text width */
  font-size: 0.9rem; /* Smaller text size */
}

.vision-image,
.mission-image {
  width: 150px; /* Reduce image size */
  height: auto;
  margin-left: 10px; /* Adjust the margin */
}
}

/* Smaller screens (phones) */
@media (max-width: 480px) {
.vision-content p,
.mission-content p {
  width: 50%; /* Further reduce text width */
  font-size: 0.8rem; /* Smaller text size */
}

.vision-image,
.mission-image {
  width: 60px; /* Reduce image size more */
  margin-left: 5px; /* Adjust the margin */
}
}
