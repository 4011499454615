/* .services .container {
  
} */
.services {
  position: relative;
  text-align: center;
  padding: 20px;
  overflow: visible ;
}

.Square2-img {
  position: fixed;
  top: 100px;
  right: -20px; /* Adjust this value as needed to control the horizontal position outside the container */
  width: 30%; /* Makes the image responsive */
  max-width: 300px; /* Sets a maximum size for the image */
  z-index: -1; /* Positions the image behind other content */
}

.wave-row {
  margin-top: 410px; /* Adds some space above the row */
}
.wave-img{
  width: 100vw; /* Full viewport width */
  max-width: 100%; /* Ensures no extra width */
  position: relative; /* Ensures it stays within layout */
  left: 50%; /* Centers it */
  transform: translateX(-50%); /* Centers it exactly */
  overflow: visible;
  margin-top: 420px;
} 
.titletext {
  text-align: left; /* Align text to the start */
  font-weight: bold;
  margin-top: -450px;
  font-size: 2.5rem;
  margin-bottom: 30px;
  margin-left: 20px;
  font-family: 'Poppins',sans-serif;
}

.para-text {
  text-align: left; /* Align text to the start */
  margin-bottom: 20px;
  width: 70%;
  font-size: 1.8rem;
  color: black; /* Optional for paragraph color */
  line-height: 1.2; /* Optional for readability */
  margin-left: 20px;
  font-family: 'Jost',sans-serif;
}


.services-heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 80px;
  margin-top: 200px;
  text-align: center;
  color: #0A466D;
}

.container {
  margin-top: 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  row-gap: 18px; /* Adjust this value to control vertical spacing */
}


.col-md-4, .col-sm-6, .col-xs-12 {
  display: flex;
  justify-content: center;
}

.mb-4 {
  margin-bottom: 30px;
}

.trainingcard .titletext{
    width: 100%;
} 

@media (max-width: 768px) {
  .Square2-img {
    right: -20px; /* Adjust positioning for smaller screens */
    width: 60%; /* Increase image size for better visibility on small screens */
    max-width: 150px;
    top: -300px;
  }
  .wave-img{
    margin-top: 280px;
    margin-bottom: 300px;
  }
  .titletext {
    text-align: left; /* Align text to the start */
    font-weight: bold;
    margin-top: -300px;
    font-size: 1.5rem;
  }
  
  .para-text {
    text-align: left; /* Align text to the start */
    margin-bottom: 20px;
    width: 80%;
    font-size: 1rem;
    color: black; /* Optional for paragraph color */
    line-height: 1.2; /* Optional for readability */
  }
  
  .services-heading {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 50px;
    margin-top: 200px;
    text-align: center;
  }
}