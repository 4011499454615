.footer {
  background-color: #f7941d4d; /* Light background color for footer */
  padding: 0px 0px; /* Padding for top and bottom */
}

.container {
  width: 100%; /* Make it responsive */
  max-width: 1140px; /* Set a max width */
  /* height: 100vh; */
}

.footer-content {
  display: flex; /* Use flexbox for layout */
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: space-between; /* Space between items */
  align-items: flex-start; /* Align items to the top */
}

.footer-content-left, 
.footer-content-center, 
.footer-content-right {
  flex: 1; /* Each section takes equal space */
  min-width: 250px; /* Minimum width for responsiveness */
  margin: 10px; /* Margin between sections */
}
.footer-content-center,
.footer-content-right {
  list-style: none;
  margin-top: 20px; /* Adjust the value as needed */
  text-align: center;
  text-align: justify;
}
.Flogo-img {
  width: 100%; /* Responsive width */
  max-width: 250px; /* Maximum width */
  height: auto; /* Maintain aspect ratio */
  margin-top: -10px;
}
.footer-content-left{
   margin-left: -50px;
}
table {
  width: 100%; /* Table takes full width */
  border-collapse: collapse; /* Collapse borders */
}

td {
  padding: 5px; /* Padding in table cells */
  border: 1px solid transparent; /* Borders for visibility */
}
.services-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-left: -50px;
  margin-top: -50px;
  margin-bottom: 60px;
}

.services-content {
  margin-top: 60px; /* Adjust this value as needed */
}.services-list li {
  margin-bottom: 10px; /* Adjust the value as needed */
  list-style-type: disc; 
  padding-left: 10px;    
}


.services-list li:last-child {
  margin-bottom: 0; /* Ensure no extra margin for the last item */
}


h3, h4, h5 {
  margin-bottom: 10px; /* Space below headings */
  text-align: center;
}
.footer-content-center ul{
  list-style-type: none; /* Adds bullet points */
  text-decoration: none;
  padding-left: 20px; /* Adds space between bullets and text */
  margin-top: 40px;

}
.footer-content-center ul li a {
  text-decoration: none; /* Removes underline from links */
  color: black;
}

.footer-content-center ul li a:hover {
  text-decoration: underline; /* Optional: Add underline on hover for better UX */
  color: #075d97;
  font-weight: bold;
}
.contact-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 25px;
  text-align: center;
  margin-left: 60px;
  margin-top: 10px;
}

.contact-list {
  list-style: none;
  padding: 0;
}

.contact-list li {
  display: flex;
  align-items: flex-start;  /* Align icon and text vertically */
  margin: 35px 0;
}

.contact-icon {
  width: 24px; /* Adjust size as needed */
  height: 24px;
  margin-right: 10px; /* Space between icon and text */
  flex-shrink: 0; /* Prevent icon from shrinking */
}

.contact-text {
  flex: 1; /* Allow text to take remaining space */
}

.follow-on{
  margin-top: 10px;
}
.follow-title{
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.footer-copyright {
  text-align: left; /* Center copyright text */
  margin-top: -40px; /* Space above copyright */
  margin-bottom: 10px;
 

}

/* Footer.css */
.icon-img {
  width: 250px; /* Adjust the width value as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: -10px;
}
@media (max-width: 576px) {
  .footer-content {
    flex-direction: column; /* Stack the sections vertically */
    align-items: center;    /* Center align items */
  }

  .footer-content-left, 
  .footer-content-center, 
  .footer-content-right {
    flex: none;          /* Remove flex properties */
    width: 100%;         /* Make sections take full width */
    text-align: center;  /* Center align text */
    margin-bottom: 20px; /* Add space between sections */
  }

  .footer-content-left {
    order: 1; /* First section */
  }

  .footer-content-center {
    order: 2; /* Second section */
  }

  .footer-content-right {
    order: 3; /* Third section */
  }

  .contact-list li {
    flex-direction: column; /* Stack contact icon and text */
    align-items: center;    /* Center align contact details */
    text-align: center;
  }

  .contact-icon {
    margin-bottom: 5px; /* Add space below icons */
  }

  .icon-img {
    margin: 20px 0px; /* Space above and below the icon image */
    margin-bottom: 30px;
  }
  .footer-copyright {
  text-align: center;
   
  
  }
}

/* Media Queries for Responsiveness */
/* @media (max-width: 768px) {
  .footer-content {
    flex-direction: column; 
    align-items: center; 
  }

  .footer-content-left, 
  .footer-content-center, 
  .footer-content-right {
    width: 100%; 
    text-align: center; 
  }

  .footer-content-left img {
    max-width: 150px;
  }
  .icon-img {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .footer-content-left {
    margin-bottom: 20px; 
  }

  .contact-icon {
    width: 18px; 
  }
  
} */
