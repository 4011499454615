Container Styling
.container {
  max-width: 100%; /* Set the maximum width of the container */
  margin: 0 auto; /* Center the container horizontally */
  padding: 0 20px; /* Add some padding for spacing on the sides */
}

.serviceshead-image {
    width: 100%;
    height: 620px;
    display: block;
    margin-top: -5px;
    
  }
  .page-content {
    padding-top: 100px; /* Adjust based on the height of your navbar */
  }

  .page-heading{
    color:#0A466D;
    text-Align:center;
    font-Weight:bold;
    margin-Top:30px;
    margin-Bottom:10px;
    font-Size:3rem;
  }

  .software {
    margin-top: 30px;
    font-weight: bold; /* Make the text bold */
}

  .research{
    margin-top: 30px;
    font-weight: bold;
}
  .digital{
    margin-top: 30px;
    font-weight: bold;
  }

  .iot{
    margin-top: 30px;
    font-weight: bold;
  }
  
  
  .middle-image {
    display: block;
    width: 350px; 
    height: 300px;
    margin-left: 150px;
    margin-right: auto;
    margin-top:50px; 
   
  }
  
  .text, .text2 {
    text-align: justify;
    line-height: 1.6;
    font-family: 'Jost', sans-serif;
    font-size: 1.2rem; 
  }
  
  .text {
    margin-left: 550px;
    margin-top: -300px;
    text-align: justify;
  }


  
  .text2 {
    margin-top: 30px; 
    
  }
  .chooseus-heading{
    color:#0A466D;
    font-Weight:bold; 
    margin-Top:10px;
    margin-Bottom:30px;
    font-Size:3rem;
    text-Align:center;
  }

  .card2 {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 200px; /* Adjust width to fit your design */
    height: 180px; 
    display: flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
 
  
    margin-bottom: 30px;
    /* Removed margin-left and margin-right for better spacing control */
  }

  /* Card image styling */
  .card2 img {
    width: 100px; 
    height: auto;
    margin-top: 10px; 
  }
  
  /* Card heading */
  .card2 h3 {
    color: #0A466D;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 1rem; 
    text-decoration: none;
    margin-top: 10px;
  }
  
  /* Container for cards */
  .card2-container {
    display: flex;
    flex-wrap: wrap; /* Ensure wrapping on smaller screens */
    justify-content: space-evenly; /* Distribute space evenly between items */
    gap: 0; /* Set gap to 0 to remove space between cards */
    margin-left:30px;
    width: 96%;
     
  }
  
  
  @media (max-width: 768px) {
      .titletext2 {
        font-size: 3rem; /* Smaller font size for tablets */
        margin-top: -400px;
        margin-left: 80px; /* Remove extra margin */
        
      }
    
      .para-text2 {
        font-size: 1.3rem; /* Smaller font size for tablets */
        margin-top: -450px;
        margin-left: -50px; /* Remove extra margin */
        text-align: center; /* Center-align text */
        width: 100%; /* Ensure it fits the container */
      }
    
      .serviceshead-image {
        width: 100%; /* Ensure the image fits the container width */
        height: 450px; /* Maintain aspect ratio */
        margin-top: -100px; /* Adjust margin for tablets */
        margin-bottom: 30px;
      }
    
    .middle-image {
      width: 100%;
      margin-top: 20px;
      margin-left: 10px;
    }
  
    .text, .text2 {
      margin: 0 10px; /* Adjust margins for smaller screens */
      font-size: 1.3rem; /* Optionally reduce font size for smaller screens */
    }

    .card2-container {
      gap: 15px; /* Adjust space between cards */
    }
  
    .card2 {
      max-width: 100px;
      height: auto; /* Reduce max-width for smaller screens */
    }
  
    .card2 img {
      width: 90%; /* Adjust image width */
    }
  
    .card2 h3 {
      font-size: 1rem; /* Further adjust font size */
    }
  }
  
  /* Ensure full width for small screens */
@media (max-width: 480px) {
  .serviceshead-image {
      width: 100%; /* Full width of the container */
      height: 200px; /* Adjust height for small screens */
      margin-top: -20px; /* Reduce top margin */
  }

  .page-content {
      padding-top: 300px; /* Adjust based on the height of your navbar or header */
  }
  
  .page-heading{
    color:#0A466D;
    text-Align:center;
    font-Weight:bold;
    font-Size:2rem;
    margin-top: -200px;
   
  }

  .titletext2 {
      font-size: 1.5rem; /* Smaller font size for mobile */
      margin-top: -220px; /* Reduce top margin */
      margin-left: 50px; /* Adjust left margin for smaller screens */
  }

  .para-text2 {
      font-size: 1rem; /* Smaller font size for mobile */
      margin-top: -480px; /* Reduce top margin */
      margin-left: 10px; /* Adjust left margin for smaller screens */
      text-align: center; /* Center-align text */
      width: 75%; /* Full width */
  }

  .middle-image {
      width: 90%; /* Full width of the container */
      height: auto; /* Maintain aspect ratio */
      margin-top: 20px; /* Adjust top margin */
      margin-left: 20px; /* Remove left margin */
  }

  .text, .text2 {
      font-size: 1rem; /* Smaller font size for mobile */
      margin: 0 10px; /* Adjust margins for smaller screens */
  }

  .chooseus-heading{
    color:#0A466D;
    font-Weight:bold; 
    margin-Top:30px;
    margin-Bottom:30px;
    font-Size:2rem;
    text-Align:center;
  }

  .card2 {
    width: calc(50% - 10px); /* Two cards per row, accounting for gap */
    max-width: 180px; /* Limit maximum width */
    height: auto; /* Adjust height based on content */
    margin: 0; /* Remove margin around each card */
    
  }

  .card2 img {
      width: 50%; /* Adjust image width */
  }

  .card2 h3 {
      font-size: 1.2rem; /* Smaller font size for headings */
  }

  .card2-container {
    display: flex;
    flex-wrap: wrap; /* Allow cards to wrap onto the next line */
    gap: 10px; /* Space between cards */
    justify-content: space-between;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
