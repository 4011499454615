/* src/Component/Card/Card.css */
.card {
  position: relative; 
  border: 1px solid #ddd;
  border-radius: 100px; 
  overflow: visible; 
  box-shadow: 0 8px 4px rgba(0, 0, 0, 0.1);
  width: 350px; 
  height:450%; 
  margin: 10px 10px 30px 0;
  display: flex;
  flex-direction: column; 
  background-color: white;
  transition: transform 0.3s, box-shadow 0.3s;
  padding: 20px;
}
  
.card:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to bottom, #0A466d, #f7941d);
  color: white; /* Gradient background */
}
  
  .card-content {
    padding: 20px;
    height: 90%; 
    overflow: hidden;
  }
  
  .card-title {
    width: 100%;
    margin-top: 30px;
    font-size:2rem;
    text-align: center;
    font-weight:600;
   
  }
  
  .card-description {
    margin: 20px 10px;
    color: black;
    height: 70px;
    font-size: 1.3rem;
    align-content: center;
    font-weight: normal;
    margin-top: 40px;
    text-align: justify;
    font-family: 'Jost',sans-serif;
  }
  

.card:hover .card-title,
.card:hover .card-description {
  color: white; /* Change title and description text color to white on hover */
}
  .card-circle {
    position: absolute;
    top: -60px;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    width: 100px; 
    height: 100px; 
    background-color:white; 
    border-radius: 50%; 
    overflow: visible; 
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) ;
  }
  
  .card-circle img {
    width: 50%; 
    height: 50%; 
    margin-top: 25px;
    margin-left:25px;
    margin-right: 25px;
    }
  
    /* Mobile Styles */
@media (max-width: 768px) {
  .card {
    width: 90%;
    height: auto; 
    margin: 20px auto; /* Center the card and adjust margin */
  }

  .card-title {
    font-size: 1.8rem; /* Adjust font size for smaller screens */
    margin-top: 30px; /* Reduce margin for mobile view */
  }

  .card-description {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
    margin: 15px; /* Reduce margins for mobile view */
  }

  .card-circle {
    width: 80px; 
    height: 80px; 
    top: -40px; /* Adjust circle position for mobile */
  }

  .card-circle img {
    width: 60%; /* Adjust image size within circle */
    height: 60%;
    margin: 20px; /* Adjust margins for image inside circle */
  }
}
    @media (max-width: 480px) {
      .card {
        width: 90%; /* Increase width for very small screens */
        height: auto; 
        margin: 20px auto; /* Further adjust margins */
        border-radius: 20px; /* Adjust border-radius for smaller screens */
      }
      
      .card:hover {
        transform: scale(1.02);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        background: linear-gradient(to bottom, #0A466d, #f7941d);
        color: white; /* Gradient background */
      }
      
      .card-title {
        font-size: 1.5rem; /* Further reduce font size */
        margin-top: 100px; 
        text-align: center;
        margin-left: 10px;
        width: 90%;
      }
    
      .card-description {
        font-size: 1rem; /* Further reduce font size */
        margin: 10px;
        font-family: 'Jost',sans-serif;/* Further reduce margins */
      }
    
      .card-circle {
        width: 80px; 
        height: 80px; 
        top: -40px; /* Adjust circle position for very small screens */
      }
    
      .card-circle img {
        width: 50%; /* Adjust image size within circle */
        height: 50%;
        
      }
    }