/* General Styling */
.contact-container, .form-container, .location-container {
  padding: 20px;
}

.contacthero-image {
  width: 100%;
  height:85vh;

  margin-top: 90px;
}

/* Text and Form Styling */
.contact-top {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 70px;
  font-family: 'Jost',sans-serif;
}
.contact-form {
  border: 2px solid #ddd;
}
.form-control {
  font-size: 1rem;
  padding: 12px;
}

.btn-primary {
  padding: 10px 20px;
  font-size: 1rem;
}

/* Contact Details Styling */
.contactus li {
  margin-bottom: 20px; /* Adjust the space between list items */
}

.contactus-icon {
  width: 30px; /* Set the width of the icon */
  height: 30px; /* Set the height of the icon */
  margin-bottom: 33px;
  margin-right: 50px;
}

.contact1-text {
  font-size: 1.2rem;
  line-height: 1.2;
  font-family: 'Jost',sans-serif;
}
.paragraph-spacing {
  margin-bottom: 35px;
}
.contactus{
  margin-top: 20px;
}
.form-container form {
  border: 2px solid #ccc; /* Adjust border color and width as needed */
  border-radius: 8px; /* Optional: adds rounded corners */
  padding: 20px; /* Adds padding inside the border */
  margin-top: -20px; /* Optional: space above the form */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
}
@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.scroll-animation {
  opacity: 0;
  animation: slideInRight 1s ease-out forwards;
}

.scroll-animation.delay-1 {
  animation-delay: 0.2s;
}

.scroll-animation.delay-2 {
  animation-delay: 0.4s;
}

.scroll-animation.delay-3 {
  animation-delay: 0.6s;
}


/* Responsive Adjustments */
@media (max-width: 768px) {
  .contact-top {
    font-size: 1.5rem;
    margin-top: 10px;
  }
  
  .form-control, .btn-primary {
    font-size: 0.9rem;
  }

  .contactus-icon {
    width: 20px;
    height: 20px;
  }

  .contact1-text {
    font-size: 0.9rem;
  }

  .contact-container, .form-container, .location-container {
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .contact-top {
    font-size: 1.2rem;
    margin-top: 100px;
  }
  
  .contactus-icon {
    width: 18px;
    height: 18px;
  }

  .contact1-text, .btn-primary, .form-control {
    font-size: 0.8rem;
  }

  .contact-container, .form-container, .location-container {
    padding: 10px;
  }
}
