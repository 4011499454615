
/* Base styles */
.responsive-text {
  font-size: 3vw; /* Dynamically scale based on viewport */
  line-height: 1.4;
 margin-top: 70px;
 margin-left: 170px;
 width: 120%;
 font-weight: 500;
}

.img-size {
  width: 60%; /* Limit max width for the image */
  height: 40vh; /* Maintain aspect ratio */
 margin-left: 250px;
 margin-top: -30px;
}

.fixed-square-img {
  position: fixed;
  right: 0;
  top: 95px; /* Remove top margin */
  width: 23%;
  max-width: 350px;
  z-index: 10; /* Keep above other content */
  height: 80vh;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .responsive-text {
      font-size: 3vw;
      width: 130%;
  }

  .img-size {
      max-width: 80%;
  }

  .fixed-square-img {
      width: 40%;
  }
}

@media (max-width: 768px) {
  .responsive-text {
      font-size: 3vw;
      margin-left: -200px;
      margin-top: 40px;
  }

  .img-size {
      width: 50%;
      margin-left: -250px;
  }

  .fixed-square-img {
      width: 35%;
  }
}

@media (max-width: 576px) {
  .responsive-text {
      font-size: 3vw;
      margin-left: -150px;
      margin-top: 40px;
  }

  .img-size {
      width: 40%;
      margin-left: -180px;
      margin-top: -20px;
  }

  .fixed-square-img {
      width: 33%;
      margin-top: -20px;
  }
}




.wave2-img {
  margin-top: 170px;
  width: 100%;
}
.about-text {
  width: 100%;
  font-family: 'Jost',sans-serif;
  font-size: 1.2rem;
  margin-top: -10px;
}
.about-image {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 20px;
margin-right: 20px;
border-radius: 8px;
    box-shadow: 0 4px 8px #0003;
}

.more-image{
  width: 25%;
  margin-left: 480px;
  margin-top: -40px;
}
.top-row {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 70px;
  margin-bottom: 20px;
}
.services-head{
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
  color: #0a466d;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 2rem;
}

.bottom-row {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 70px;
}

.course-image {
  width: calc(33.33% - 10px); 
  max-width: 200px; 
}

.course-head {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
  color: #0a466d;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 2rem;
}

.viewmore-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: auto;
  margin-top: 20px;
}


.center-container {
  width: 70%;
}
.contact-container{
 margin-top: -80px;
}
.Contact-head {
  text-align: center;
  margin-top: -80px;
  margin-bottom: 70px;
  color: #0a466d;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 2rem;
}

.contact-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-content-left,
.contact-content-center,
.contact-content-right {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: -50px;
}

.contact-content row{
  margin-top: -200px;
}
.contact-content-left {
  text-align: left;
}

.contact-content-right iframe {
  width: 100%;
  height: 330px;
  border: none;
  margin-top: -80px;

}

.hour-head {
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: -100px;
  font-family: 'Jost',sans-serif;
}

.contact-content-center td{
  text-align: justify;
  font-size: 1.2rem;
  font-family: 'Jost',sans-serif;
}
.table-responsive {
  width: 100%;
}

.table {
  width: auto;
  margin: 0 auto;
  line-height: 1;
  border-collapse: collapse;
}

.colon {
  padding: 0 10px;
}

.sunday {
  color: red;
}

.hour-day {
  font-weight: bold;
  color: black;
}

.hour-time {
  font-weight: bold;
}

.icon1-img {
  margin-top: 10px;
  width: 70%;
margin-left: -20px;
margin-bottom: 10px;
}
.Nlogo-img {
  width: 70%;
  margin-top: -100px;
  height: auto;
  max-width: 70%;
  margin-right: 100px;
}

.table-responsive {
  display: flex;
  justify-content: flex-start;
  width: auto;
  border: none;
}


.contact-list h3 {
  font-size: 1.3rem;
  text-align: justify;
  font-family: 'Jost',sans-serif;
  font-weight: bold;
}
.contact-list p{
  font-size: 1.2rem;
  font-family: 'Jost',sans-serif;
  margin-top: -5px;
  margin-bottom: 20px;
}
.blurwave-img{
  margin-top: -20px;
 height: 150px;
}

@media (max-width: 1200px) {
  .center-text {
    font-size: 1.8rem;
  }

  .services-head,
  .course-head {
    font-size: 1.6rem;
  }

  .Square-img {
    max-width: 30%;
  }
}

@media (max-width: 992px) {
  .center-text {
    font-size: 1.6rem;
    width: 100%;
    margin-left: 100px;
  }

  .JS-gif {
    max-width: 350px;
  }

  .Square-img {
    width: 25%;
    max-width: 30%;
  }
  .about-image{
    width: 50%;
    margin-right: -10px;
  }
  .more-image{
    margin-left: 430px;
  }
}

@media (max-width: 768px) {
 .wave2-img{
  margin-top: 50px;
 }
  .about-text {
    width: 150%;
    font-size: 1rem;
    font-family: 'Jost',sans-serif;
  }
  .about-image {
    width: 70%;
    margin-right: 0px;
  }
  .more-image{
    margin-left: 70px;
  }

  .services-head{
    font-size: 1.5rem;
    margin-top: 10px;
    margin-bottom: 40px;

  }
.top-row{
  width: 100%;
  gap: 10px;
}
.bottom-row{
  gap: 20px;
  width: 100%;
}
.viewmore-img{
 margin-left: 200px;
 width:20%;
}
.icon1-img{
  margin-bottom: 50px;
}
}

@media (max-width: 576px) {

 .wave2-img{
  margin-top: 70px;
 }
 .about-text {
  width: 62%;
  font-family: 'Jost',sans-serif;
  font-size: 0.9rem;
  text-align: justify;
  margin-left: 4px;
}
.about-image {
  width: 35%;
  margin-top: -270px;
  margin-right: -10px;
  margin-bottom: 90px;
}

.more-image{
  width: 20%;
  margin-left: 150px;
  margin-top: -60px;
}

.top-row {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}
.Nlogo-img {
  width: 40%;
  margin-top: -40px;
  height: auto;
  max-width: 70%;
  margin-right: 0px;
}
  .contact-content {
    flex-direction: column;
    align-items: center;
  }
  .contact-list h3 {
    text-align: center;
  }
  .contact-list{
    text-align: center;
  }
  .Contact-head {
    font-size: 1.8rem;
  }

  
.contact-content-right iframe {
  width: 100%;
  height: 300px;
  border: none;
  margin-top: 50px;
  margin-bottom: 60px;
}

.hour-head {
  margin-bottom: 15px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 10px;

}
} 


@media (max-width: 480px){
  .center-text{
    font-size: 1rem;
  }
  .about-text{
    width: 100%;
    margin-top: 200px;
  }
.about-image{
  width: 70%;
  margin-right: 50px;
  margin-bottom: 250px;
  margin-top: -440px;
}
.more-image{
  margin-left: 70px;
  width: 30%;
}
.services-head{
  margin-top: -30px;
}
.viewmore-img{
  width: 30%;
  margin-left: 150px;

}
}