/* General card styles */
.learncard {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 450px;
  margin-bottom: 20px;
  border-bottom: 10px solid #0A466D; 
}

/* Hover effect for the card */
.learncard:hover {
  transform: translateY(-5px);
}

/* Image styles */
.learncard-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
}

/* Card content styles */
.learncard-content {
  padding: 15px;
}

/* Title styles */
.learncard-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

/* Description styles */
.learncard-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  text-align: center;
  text-align: justify;
}

/* Responsive design for small devices (mobile) */
@media (max-width: 768px) {
  .learncard {
    margin-bottom: 20px;
  }

  .learncard-title {
    font-size: 1.3rem;
  }

  .learncard-description {
    font-size: 0.9rem;
  }
}

/* Responsive design for extra-small devices (mobile portrait) */
@media (max-width: 576px) {
  .learncard {
    margin-bottom: 15px;
  }

  .learncard-title {
    font-size: 1.2rem;
  }

  .learncard-description {
    font-size: 0.8rem;
  }
}
