.career-wrapper { 
 
  padding: 20px 0;
}

.career-title { 
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin: 20px 0;
  width: 120%;
  margin-top: -150px;
  font-family: 'Poppins',sans-serif;
}

.career-description {
  font-size: 1.8rem;
  color: black;
  text-align: center;
  margin: 10px 0;
  width: 120%;
  font-family: 'Poppins',sans-serif;
}

.model-img, .square-img {
  max-width: 100%;
  height: auto;
}

.model-img {
  width: 55%; /* Default size for large screens */
  margin: 20px auto;
  margin-top: 10px;
}

.square-img {
  width: 70%; /* Default size for large screens */
  margin: 60px auto;
  margin-left: 200px;
}

.wave-container {
  margin-top: -220px;
}

.careerwave-img {
  max-width: 100%;
  height: auto;
  margin-bottom: 30px;
}
.google-form-container {
  width: 100%;
  max-width: 800px; /* Optional: Set a maximum width */
  height: 0;
  padding-top: 75%; /* Aspect ratio: 4:3 */
  position: relative;
}

.google-form-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
@media (max-width: 992px) { /* Tablets and smaller desktops */
  .career-title {
    font-size: 2rem;
    width: 200%;
  }
  .career-description {
    font-size: 1.2rem;
  }
  .model-img, .square-img {
    width: 60%;
  }
  .wave-container {
    margin-top: -120px;
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) { /* Small tablets and large phones */
  .career-title {
    font-size: 1.8rem;
    width: 190%;
  }
  .career-description {
    font-size: 1rem;
    margin: 10px auto;
    width: 140%;
  }
  .square-img {
    margin-left: 150px;
    width: 80%;
  }
  .model-img{
    width: 80%;
    margin: 20px auto;
  }
  .wave-container {
    margin-top: -120px;
    margin-bottom: 30px;
  }
}

@media (max-width: 576px) { /* Small phones */
  .career-title {
    font-size: 1.5rem;
margin-top: -30px;
width: 200%;
  }
  .career-description {
    font-size: 0.9rem;
    text-align: justify;
    width: 180%;
  }
  .square-img{
    width: 80%;
    top: 20px;
  } 
  .model-img{
    width: 80%;
    margin: 10px auto;
  }
  .wave-container {
    margin-top:-100px;
    margin-bottom: 30px;
  }
}


@media (max-width: 480px) { /* Small screens like 480px */
  .career-title {
    font-size: 1.2rem;
    margin-top: -20px;
    width: 190%;
    margin-left: -20px;
  }
  .career-description {
    font-size: 0.8rem;
    text-align: justify;
    width: 160%;
    margin: 5px auto;
  }
  .model-img, .square-img {
    width: 90%;
    margin: 20px auto;
  }
  .square-img {
    margin-top: 20px;
    margin-left: 100px;
  }
  .wave-container {
    margin-top: -80px;
    margin-bottom: 20px;
  }
}