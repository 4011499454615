/* Navbar.css */
.header {
  width: 100%; /* Ensure header is full width */
  z-index: 1000; /* Keep navbar on top */
}

.navbar {
  height: 15%;
  max-height: 15%;
  width: 100%; 
  background-color: white !important; 
  background: fixed;
}
.nlogo1{
  width:250px;
  margin-top: -20px;
}
.navbar-brand img {
  width:250px; /* Adjust the width as needed */
  height: auto; 
  margin-right: 70px;
}
.navbar-nav {
  display: flex; /* Ensure it's a flex container */
  gap: 20px; /* Add gap between nav links (adjust as needed) */
}

.navbar-nav .nav-link {
  color: #333; /* Link color */
  transition: color 0.3s; /* Transition for hover effect */
  font-size: 1.2rem;
  font-weight:bold ;
  text-decoration: none;
  display: flex; /* Ensures that the nav links are displayed in a row */
  flex-direction: row; /* Aligns items in a row */
  margin-left:auto ; 
  white-space: nowrap;

}

.navbar-nav .nav-link:hover {
  color:#F7941d; /* Change color on hover */
}

.nav-link.active {
  color: #F7941d !important; /* Orange color for active links */
  font-weight: bold;  /* Make active link bold */
}
.contact-navlink {
  background-color: #0a466d; /* Background color */
  color: white; /* Text color */
  font-size: 1rem;
  font-weight: bold;
  border-radius: 6px;
  padding: 5px 10px; /* Adjust padding for contact link */
  text-decoration: none;
  position: relative; /* Allow positioning */
  top: 10px; /* Position the contact link above others */
  margin-right: 0; /* Remove right margin for the contact button */
  margin-left: 20px;
  
}

.contact-navlink .active {
  background-color: #f7941d; /* Orange background for active link */

  
}

@media (max-width: 992px) {
  .navbar-brand img {
    width:150px; /* Adjust the width as needed */
    height: auto; 
  }
  .navbar {
    height: 10%;
    max-height: 15%;
    width: 100%; 
    background-color: white!important; 
    background: fixed;
  }
  .navbar-toggler{
    margin-top: -20px;
  }
  
.navbar-brand img {
  width:250px; /* Adjust the width as needed */
  height: auto; 
  margin-top: -30px;
}
}
/* Ensure mobile responsiveness */
@media (max-width: 768px) {
  .navbar-brand img {
    width:150px; /* Adjust the width as needed */
    height: auto; 
  }

  .navbar-nav .nav-link {
    padding: 10px 15px; /* Adjust padding for touch targets */
  }
}
