/* General Styles */
.learning-container {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.mediabg {
  width: 100%;
  height: 63vh;
  overflow: visible;
  object-fit: cover; 
  margin-top: 100px;
}

.mediahero {
  position: absolute;
  top: 160px;
  left: 75%;
  transform: translateX(-50%);
  width: 35%;
}

.learn-head {
  font-size: 1.8rem;
  margin: 20px auto;
  color: white; /* White text for better visibility on dark backgrounds */
  width: 40%;
  padding: 0 15px;
  position: absolute;
  top: 30%; /* Vertically center the text */
  left: 10%;
  z-index: 2; /* Ensure text appears above the image */
  font-weight: bold; /* Optional: makes the text more prominent */
  line-height: 1.6; /* Adjust line height for better readability */

}

.learn-course {
  font-size: 2.5rem;
  font-weight: bold;
  color:  #0A466D;
/* margin-left: 800px; */
text-decoration: underline;
margin-top: 70px;
margin-bottom: 100px;
text-align: center;
}

.row{
  margin-top: 18px;
}
/* Card Adjustments */
.card {
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.card img {
  max-width: 100%;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-title {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #333;
}

.card-description {
  font-size: 1rem;
  margin: 10px;
  color: #555;
}

/* For Medium Devices (tablets and below) */
@media (max-width: 992px) {
  .mediahero {
    top: 90px;
    width: 45%;
    max-width: 350px;
  }
  .mediabg{
    height: 350px;
  }
  
  .learn-head {
    font-size: 1.3rem;
    width: 40%; /* Make head text more readable on smaller screens */
  }

  .learn-course {
    font-size: 2rem;
    text-align: center;
    margin-top: -50px;
    margin-bottom: 60px;
  }

  .card {
    width: 90%; /* Make the cards take up more space on smaller screens */
  }
}

/* For Small Devices (phones and below) */
@media (max-width: 768px) {
  .mediahero {
    width: 60%; /* Adjust image size for very small screens */
    max-width: 300px;
    top: 80px;
  }
  .mediabg{
    height: 280px;
  }

  .learn-head {
    font-size: 1.1rem;
    width: 40%;
  }

  .learn-course {
    font-size: 1.8rem;
    text-align: center;
    margin-top: -30px;
 
  }

  /* Stack the cards vertically */
  .card {
    width: 100%;
    margin-bottom: 15px; /* Add some margin between the cards */
  }
  
  /* Adjust text within cards */
  .card-title {
    font-size: 1rem;
  }

  .card-description {
    font-size: 0.9rem;
  }
}

/* For Extra Small Devices (Mobile Portrait) */
@media (max-width: 576px) {
  .mediahero {
    width: 60%; /* Adjust image size for very small screens */
    max-width: 190px;
    top: 110px;
    margin-left: 30px;
  }
  .mediabg{
    height: 200px;
  }

  .learn-head {
    font-size: 1rem;
    width: 55%; /* Make sure text has enough padding on smaller devices */
    margin-left: -30px;
    top:100px;
  }

  .learn-course {
    font-size: 1.3rem;
    margin-top: -40px;
    text-align: center;
  }

  .card-title {
    font-size: 0.9rem; /* Make card title font size smaller */
  }

  .card-description {
    font-size: 0.8rem; /* Smaller description font for mobile */
  }
}