/* Container for the entire media section */
.media-container .container {
  max-width: 100%; /* Adjust this to your desired width */
  width: 100%; /* Makes it full-width if you want it to span the entire screen */
}

.media-container {
  text-align: center;
  width: 100%;
  padding-top: 80px;
}


/* Style for the hero image */
.mediahero-image {
  width: 100%;

  height: 88vh;
  margin-bottom: 10px;
  
}

.media-head-image{
  margin-top: -600px; 
  width: 450px;
  margin-left: -100px;
  margin-right: 600px;
  align-items: start;
 
}

.media-nav {
  margin-top: -15px;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Jost', sans-serif;
  color: black;
  
}

.media-nav li {
  margin: 0 70px; /* Increase margin to create a gap */
  position: relative;
}

.media-nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: black;
}

.medianav-link  {
  color: black; /* Black color for the default state */
  transition: color 0.3s ease; /* Smooth color transition */
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}


.nav-link:hover,
.active-link {
  color: #f7941d;
} 

/* Style for the bullet */
.media-nav li::before {
  content: '';
  display: inline-block;
  width: 20px; /* Width of the kite bullet */
  height: 20px; /* Height of the kite bullet */
  background-color: white; /* Default color for the bullet */
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); /* Create a kite shape */
  position: absolute; 
  left: -30px; /* Position the bullet to the left */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust vertical alignment */
}

/* Active bullet styles */
.media-nav li a.active-link::before,
.media-nav li.active::before {
  background-color: #f7941d; /* Change to color for the active bullet */
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Adds space between images */
  margin-bottom: 20px;
}

.gallery-image {
  flex: 1 1 calc(30% - 10px); /* Three images per row with spacing */
  max-width: calc(30% - 10px);
  height: auto;
}

.gallery-image img {
  width: 100%; /* Fill the width of the container */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Cover to fill the height */
}

/* Fullscreen Overlay */
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background:white;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1001; /* Make sure it's above the image */
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}

/* Optional: Style for the fullscreen image */
.fullscreen-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain; /* Maintain aspect ratio */
}

@media screen and (max-width: 992px) {
  .media-head-image{
    margin-top: -600px;
    width: 400px;
    margin-left: 50px;
  }
  .mediahero-image {
    width: 100%; 
    height: auto;/* Increase width for mobile */
    margin-left: auto;
    margin-right: auto;
  }

  .media-nav {
    font-size: 1rem;
    margin-left: -20px;
    margin-bottom: 40px;
   }
   .media-nav li {
    margin: 0 50px; /* Increase margin to create a gap */
    position: relative;
  }
  
   
}


/* Responsive styles */
@media (max-width: 768px) {
  
  .media-head-image{
    margin-top: -400px;
    width: 260px;
    margin-left: 40px;
  }
  .mediahero-image {
    width: 100%; 
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .media-nav li {
    margin: 0 20px;
  }

  .gallery-image {
    flex: 1 1 calc(50% - 10px); 
  }
}
@media(max-width:568px){
  .media-head-image{
    margin-top: -300px;
    width: 230px;
    margin-left: 20px;
    overflow: visible;
  }
  .mediahero-image {
    width: 100%;
    height: 200px;
    overflow: visible;
  }

}
@media (max-width: 480px) {
  
  .media-head-image{
    margin-top: -300px;
    width: 200px;
    margin-left: 30px;
  }
  .mediahero-image {
    width: 100%;
    height: 200px;
    margin-top: -40px;/* Full width on mobile */
  }

  .media-nav {
   font-size: 1rem;
   margin-left: -10px;
   margin-bottom: 40px;
  }

  .media-nav li {
    margin: 0 10px; /* Increase margin to create a gap */
    position: relative;
  }

  .media-nav li::before {
    width: 10px;
    height: 10px;
    margin-left: 20px;
  }

  .gallery-image {
    flex: 1 1 100%; /* One image per row */
  }
  
}